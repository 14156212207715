class config {
  // static API_ENDPOINT = "http://localhost:57560/"
  // static WEB_ENDPOINT = "http://localhost:9000/"

  static API_ENDPOINT = "https://stageapi.mindmemobile.com/"
  static WEB_ENDPOINT = "https://staging.mindmemobile.com/"
  static AFFILIATE_WEB_ENDPOINT = "https://stagingaffiliate.mindmemobile.com/"

  // static API_ENDPOINT = "https://prodapi.mindmemobile.com/"
  // static WEB_ENDPOINT = "https://m.mindmemobile.com/"
  // static AFFILIATE_WEB_ENDPOINT = "https://affiliate.mindmemobile.com/"
}
export default config
